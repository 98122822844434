* {
  padding: 0;
  margin: 0;
  font-size: 14pt;
}

body {
  background-color: #F9F6F1;
}

a {
  color: #954B83;
  text-decoration: none;
}

a:hover {
  color: #44223B;
}

.created {
  float: left;
  padding-top: 10px;
  color: lightslategray;
}

h1 {
  font-size: 22pt;
  padding: 2px 0;
}

h1.header {
  float: left;
}

div.editor h1 {
  color: #0e1013;
}

h2 {
  padding: 8px 0;
}

p {
  padding: 10px 0;
}

p.warning {
  color: red;
}

.MuiMenu-paper {
  margin-top: 24px;
}

.MuiBox-root {
  padding: 10px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo {
  height: 100%;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.header {
  height: 5vh;
  width: 100%;
  border-bottom: 1px solid black;
  color: #0D0807;
}

div.container {
  display: flex;
  flex-direction: row;
  padding: 0 0 10vh;
  clear: both;
}

div.container button {
  margin: 0 5px;
}

div.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  min-height: 76vh;
}

div.footer-container {
  height: 10vh;
  width: 100%;
  background-color: #282c34;
  flex: 1;
}

.text-field {
  margin: 10px 0;
}

ul.nav {
  list-style: none;
  text-align: left;
}

ul.nav li {
  font-size: 16pt;
  padding: 0 0 10px 10px;
}

ul.nav li a {
  text-decoration: none;
  color: #282c34;
}

ul.nav li a:hover {
  text-decoration: none;
  color: #EE6C4D;
}

div.container input, div.container textarea {
  width: 800px;
  padding: 20px;
}

div.right-column button {
  padding: 10px 20px;
  font-size: 16pt;
}

div.header-button {
  float: left;
}

div.editor {
  background-color: beige;
  min-height: 500px;
  width: 90%;
  margin-top: 10px;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 15pt;
}

div.splash {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  min-height: 300px;
  flex-wrap: wrap;
  background: url("./assets/splash.png") rgb(240, 195, 210) no-repeat left top;
}

div.row {
  padding: 10px 0;
  font-size: 18pt;
  border-bottom: 1px solid lightslategray;
  width: 100%;
}

div.splash-item {
  width: 240px;
  height: 200px;
  padding: 20px;
}

div.splash-item p {
  font-size: 16pt;
}

p.name {
  width: 80%;
  float: left;
}

div.inner-container {
  flex-direction: row;
  line-height: 2;
  padding: 10px;
  width: 98%;
}

div.footer-controls {
  padding-top: 20px;
}

div.footer-controls a {
  color: ghostwhite;
  padding-left: 20px;
}

ul.privacy-list {
  line-height: 2;
  padding: 20px;
}
